import { Injectable, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { UTEXCompanyService } from 'src/app/controllers/utex/company.controller';
import { UTEXDocumentGroupMappingService } from 'src/app/controllers/utex/document-group-mapping.controller';
import { UTEXDocumentGroupService } from 'src/app/controllers/utex/document-group.controller';
import { UTEXErrorCodeService } from 'src/app/controllers/utex/error-code.controller';
import { UTEXErrorResolutionReasonMappingService } from 'src/app/controllers/utex/error-resolution-reason-mapping.controller';
import { MassResolutionController } from 'src/app/controllers/utex/mass-resolution.controller';
import { UTEXReasonService } from 'src/app/controllers/utex/reason.controller';
import { UTEXRecordTypeService } from 'src/app/controllers/utex/record-type.controller';
import { ResolutionStatusMappingService } from 'src/app/controllers/utex/resolution-status-mapping.controller';
import { UTEXStatusService } from 'src/app/controllers/utex/status.controller';
import { UTEXRecordDTOService } from 'src/app/controllers/utex/utex-record-dto.controller';
import { AuthAllocComponentService } from 'src/app/u2-person/csc/auth-allocation/auth-allocation.service';
import { CCHHistoryService } from 'src/app/u2-shared/cch-history/cch-history.service';
import { QueuePageService } from 'src/app/u2-utex/queue/queue.service';
import { CommonPageService } from 'src/app/u2-utex/utex-common-resolution/utex-common-resolution.service';


@Injectable({
  providedIn: 'root',
})

export class CDCNCompanyService implements OnDestroy {

  private readonly _destroying$ = new Subject<void>();

  constructor(
    private readonly cchHistoryService: CCHHistoryService,
    private readonly authAllocComponentService: AuthAllocComponentService,
    private readonly commonPageService: CommonPageService,
    private readonly queuePageService: QueuePageService,
    private readonly utexDocumentGroupService: UTEXDocumentGroupService,
    private readonly utexDocumentGroupMappingService: UTEXDocumentGroupMappingService,
    private readonly utexErrorCodeService: UTEXErrorCodeService,
    private readonly utexStatusService: UTEXStatusService,
    private readonly utexReasonService: UTEXReasonService,
    private readonly utexRecordTypeService: UTEXRecordTypeService,
    private readonly utexErrorResolutionReasonService: UTEXErrorResolutionReasonMappingService,
    private readonly utexRecordDTOService: UTEXRecordDTOService,
    private readonly massResolutionController: MassResolutionController,
    private readonly gridService: CommonPageService,
    private readonly resolutionStatusMappingService: ResolutionStatusMappingService,
    private readonly companyService: UTEXCompanyService,
  ) {}

  public clearControllers(): void {
    this.cchHistoryService.clear();
    this.authAllocComponentService.clear();
    this.commonPageService.clear();
    this.queuePageService.clear();
    this.utexDocumentGroupMappingService.clear();
    this.utexDocumentGroupService.clear();
    this.utexErrorCodeService.clear();
    this.utexStatusService.clear();
    this.utexReasonService.clear();
    this.utexRecordTypeService.clear();
    this.utexErrorResolutionReasonService.clear();
    this.utexRecordDTOService.clear();
    this.massResolutionController.clear();
    this.gridService.clear();
    this.resolutionStatusMappingService.clear();
    this.companyService.clear();
  }

  ngOnDestroy(): void {
    this._destroying$.next(null);
    this._destroying$.complete();
  }

}
