import { ErrorHandler, Injectable, Injector } from '@angular/core';
import { AppConstants } from './../appConstant';

@Injectable({
  providedIn: 'root'
})
export class ErrorHandlerService extends ErrorHandler {

  constructor(private readonly injector: Injector) {
    super();
  }

  public handleError(error: any): void {
    if (!(error.rejection && error.rejection.message && error.rejection.message === AppConstants.id_tokenErrorMessages)) {
      this.logError(error);
    }
  }
  logError(error: any) {
    super.handleError(error);
  }
}
