import { EventEmitter, Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { debounceTime, map } from 'rxjs/operators';
import {
  CvetCommonService,
  IStateLookupDto,
} from 'src/app/core/services/u2api.services';
import { CommonConstants } from '../constants/common-constants';
import { ILookupInstance, FilterRequest } from './../../core/services/u2api.services';

@Injectable({
  providedIn: 'root',
})
export class CommonDropdownService {
  private states: Array<IStateLookupDto>;
  private readonly countries = [
    {
      countryId: 1,
      countryName: 'United States',
    },
  ];

  private gender: Array<ILookupInstance>;
  private ptStatus: Array<ILookupInstance>;
  private maritalStatus: Array<ILookupInstance>;
  private clientRelation: Array<ILookupInstance>;
  private billingDetail: Array<ILookupInstance>;
  private insuranceType: Array<ILookupInstance>;
  private claimLines: Array<ILookupInstance>;
  private billReport: Array<ILookupInstance>;
  private billFormat: Array<ILookupInstance>;

  constructor(private readonly service: CvetCommonService) {}

  createDebouncedSubscriptionForComboboxes(
    callbackMethod: EventEmitter<any>,
    comboBox: any,
    minCharToFilter: number = CommonConstants.numOfCharsAfterWhichFilter,
  ): void {
    comboBox.filterChange
      .asObservable()
      .pipe(debounceTime(CommonConstants.debounceTimeInput))
      .subscribe((value) => {
        if (value && value.length >= minCharToFilter) {
          callbackMethod.emit(value);
        } else {
          comboBox.toggle(false);
        }
      });
  }

  getAllStates(): Observable<Array<IStateLookupDto>> {
    if (this.states) {
      return of(this.states);
    } else {
      const filterRequest: FilterRequest = new FilterRequest();
      filterRequest.sortColumn = 'Name';
      filterRequest.sortDirection = 'ASC';
      filterRequest.filterColumn = undefined;
      filterRequest.filterValue = undefined;
      filterRequest.pageSize = 80;
      filterRequest.pageNumber = 1;
      return this.service
        .getAllState(1, filterRequest).pipe(
          map((x) => {
            this.states = x.items;
            return this.states;
          }),
        );
    }
  }
  getCountries() {
    return of(this.countries);
  }

  getGender(): Observable<Array<ILookupInstance>> {
    if (this.gender) {
      return of(this.gender);
    } else {
      return this.service.getLookupValuesForEnum('Gender').pipe(
        map((x) => {
          this.gender = x;
          return this.gender;
        }),
      );
    }
  }

  getPTStatus(): Observable<Array<ILookupInstance>> {
    if (this.ptStatus) {
      return of(this.ptStatus);
    } else {
      return this.service.getLookupValuesForEnum('PtStatus').pipe(
        map((x) => {
          this.ptStatus = x;
          return this.ptStatus;
        }),
      );
    }
  }

  getMaritalStatus(): Observable<Array<ILookupInstance>> {
    if (this.maritalStatus) {
      return of(this.maritalStatus);
    } else {
      return this.service.getLookupValuesForEnum('MaritalStatus').pipe(
        map((x) => {
          this.maritalStatus = x;
          return this.maritalStatus;
        }),
      );
    }
  }

  getClientRelation(): Observable<Array<ILookupInstance>> {
    if (this.clientRelation) {
      return of(this.clientRelation);
    } else {
      return this.service.getLookupValuesForEnum('ClientRelation').pipe(
        map((x) => {
          this.clientRelation = x;
          return this.clientRelation;
        }),
      );
    }
  }

  getBillingDetails(): Observable<Array<ILookupInstance>> {
    if (this.billingDetail) {
      return of(this.billingDetail);
    } else {
      return this.service.getLookupValuesForEnum('BillingDetail').pipe(
        map((x) => {
          this.billingDetail = x;
          return this.billingDetail;
        }),
      );
    }
  }

  getInsuranceType(): Observable<Array<ILookupInstance>> {
    if (this.insuranceType) {
      return of(this.insuranceType);
    } else {
      return this.service.getLookupValuesForEnum('InsuranceType').pipe(
        map((x) => {
          this.insuranceType = x;
          return this.insuranceType;
        }),
      );
    }
  }

  getClaimLines(): Observable<Array<ILookupInstance>> {
    if (this.claimLines) {
      return of(this.claimLines);
    } else {
      return this.service.getLookupValuesForEnum('ClaimLines').pipe(
        map((x) => {
          this.claimLines = x;
          return this.claimLines;
        }),
      );
    }
  }

  getBillReport(): Observable<Array<ILookupInstance>> {
    if (this.billReport) {
      return of(this.billReport);
    } else {
      return this.service.getLookupValuesForEnum('BillReport').pipe(
        map((x) => {
          this.billReport = x;
          return this.billReport;
        }),
      );
    }
  }

  getBillFormat(): Observable<Array<ILookupInstance>> {
    if (this.billFormat) {
      return of(this.billFormat);
    } else {
      return this.service.getLookupValuesForEnum('BillFormat').pipe(
        map((x) => {
          this.billFormat = x;
          return this.billFormat;
        }),
      );
    }
  }
}
