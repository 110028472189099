import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import {
  MSAL_INTERCEPTOR_CONFIG,
  MsalInterceptorAuthRequest,
  MsalInterceptorConfiguration,
  MsalService,
} from '@azure/msal-angular';
import {
  AccountInfo,
  AuthenticationResult,
  BrowserConfigurationAuthError,
  InteractionType,
  SilentRequest,
} from '@azure/msal-browser';
import { EMPTY, from, Observable, of } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { AuthorizationConstants } from './../../shared/constants/authorization-constants';
import { AppInsightsService } from '../services/app-insights.service';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor(
    private readonly msalService: MsalService,
    @Inject(MSAL_INTERCEPTOR_CONFIG)
    private readonly msalInterceptorConfig: MsalInterceptorConfiguration,
    private readonly appInsightsService: AppInsightsService,
  ) {}
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    return from(this.handleAccess(request, next)).pipe(switchMap(response => response));
  }

  private async handleAccess(request: HttpRequest<any>, next: HttpHandler) {
    if (
      this.msalInterceptorConfig.interactionType !== InteractionType.Popup &&
      this.msalInterceptorConfig.interactionType !== InteractionType.Redirect
    ) {
      throw new BrowserConfigurationAuthError(
        'invalid_interaction_type',
        'Invalid interaction type provided to MSAL Interceptor. InteractionType.Popup,' +
        ' InteractionType.Redirect must be provided in the msalInterceptorConfiguration',
      );
    }

    const compContext = AuthorizationConstants.companyCode;
    const subscriptionKey = environment.U2PortalAPIMSubscriptionPrimaryKey;
    let account: AccountInfo;
    if (this.msalService.instance.getActiveAccount()) {
      this.msalService
        .getLogger()
        .verbose('Interceptor - active account selected');
      account = this.msalService.instance.getActiveAccount();
    } else {
      this.msalService
        .getLogger()
        .verbose('Interceptor - no active account, fallback to first account');
      account = this.msalService.instance.getAllAccounts()[0];
    }

    const authRequest =
      typeof this.msalInterceptorConfig.authRequest === 'function'
        ? this.msalInterceptorConfig.authRequest(this.msalService, request, {
          account,
        })
        : { ...this.msalInterceptorConfig.authRequest, account };

    const scopesToConsent = environment.apiScope;
    account = account
      ? account
      : JSON.parse(localStorage.getItem('accountInfo'));
    const silentRequest: SilentRequest = {
      account,
      scopes: [scopesToConsent],
    };

    await this.msalService.instance.initialize();

    return this.msalService.acquireTokenSilent(silentRequest).pipe(
      catchError(() => {
        this.msalService
          .getLogger()
          .error(
            'Interceptor - acquireTokenSilent rejected with error. Invoking interaction to resolve.',
          );
        return this.acquireTokenInteractively(
          authRequest,
          silentRequest.scopes,
        );
      }),
      switchMap((result: AuthenticationResult) => {
        if (!result.accessToken) {
          this.msalService
            .getLogger()
            .error(
              'Interceptor - acquireTokenSilent resolved with null access token.',
            );
          return this.acquireTokenInteractively(
            authRequest,
            silentRequest.scopes,
          );
        }
        return of(result);
      }),
      switchMap((result: AuthenticationResult) => {
        this.traceRequest(result?.accessToken, compContext, result, request);
        request = request.clone({
          setHeaders: {
            Authorization: `Bearer ${result.accessToken}`,
            CompanyContext: compContext ? compContext : '',
            'Ocp-Apim-Subscription-Key': subscriptionKey,
          },
        });
        return next.handle(request);
      }),
    );
  }

  private acquireTokenInteractively(
    authRequest: MsalInterceptorAuthRequest,
    scopes: string[],
  ): Observable<AuthenticationResult> {
    this.msalService
      .getLogger()
      .verbose(
        'Interceptor - error acquiring token silently, acquiring by redirect',
      );
    const redirectStartPage = window.location.href;
    console.log('calling interaction');
    if (authRequest.account) {
      this.msalService.acquireTokenRedirect({
        ...authRequest,
        scopes,
        redirectStartPage,
      });
    }
    return EMPTY;
  }

  private traceRequest(
    token: string | null,
    compContext: string,
    result?: AuthenticationResult,
    request?: HttpRequest<any>,
  ) {
    if (request) {
      const tokenText = token && typeof token == 'string' && token?.substring(0, 5);
      const oid = result?.account?.idTokenClaims?.oid;
      const logMessage =
        `U2 Interceptor - text: ${tokenText}, compContext: ${compContext}, ` +
        `url: ${request.url}, method: ${request.method}, oid: ${oid}`;
      this.appInsightsService.trackTrace(logMessage);
    }
  }
}
