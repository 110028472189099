import { IamWrapperConstants } from '../iamWrapperConstants';
import { RolesDto } from '../services/rolesapi/rolesapi.services';
import { UsersRolesDto } from '../services/usersapi/usersapi.services';

export class GetUserRolesTransform {
  static transformOutput(allRoles: RolesDto[], userRoles: UsersRolesDto[]): any {
    const assignedU2RoleIds = userRoles
      .filter(role => role.applicationName.toLowerCase() === IamWrapperConstants.U2ApplicationName.toLowerCase())
      .map(role => role.roleId);

    const u2RolesSet: Record<number, any> = {};
    const allU2Roles = allRoles.filter(role => role.applicationName?.toLowerCase() === IamWrapperConstants.U2ApplicationName.toLowerCase());
    allU2Roles.forEach(
      role =>
        (u2RolesSet[role.roleId] = {
          roleId: role.roleId,
          roleName: role.roleName,
          isAssigned: false,
        }),
    );

    // assignedU2Roles is always a subset of allRoles
    assignedU2RoleIds.forEach(roleId => {
      const role = u2RolesSet[roleId];
      if (role !== null && role !== undefined) {
        role.isAssigned = true;
      }
    });

    const rolesData = Object.values(u2RolesSet);

    return {
      assignedRoles: rolesData.filter(role => role.isAssigned),
      availableRoles: rolesData.filter(role => !role.isAssigned),
    };
  }
}
