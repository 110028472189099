import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { ToastrService } from 'ngx-toastr';

import { PeopleFunctionService } from 'src/app/core/services/peopleapi/peoplefunctionapi.services';

export interface IAuthAllocCaregiverSummary {
  caregiverId?: number;
  caregiverFirstName?: string;
  caregiverLastName?: string;
  caregiverName?: string;
  caregiverEmployerMappingId?: number;
  wwls?: ICaregiverWWLSummary[];
}

export interface ICaregiverWWLSummary {
  wwlTotal?: number;
  wwlAllocated?: number;
  wwlAvailable?: number;
  startDate?: Date;
  endDate?: Date;
}

export interface IAuthAllocParameters {
    id?: number;
    startDate?: Date;
    endDate?: Date;
}

export interface IAuthAllocResult {
    availableUnits1?: number;
    allocatedUnits1?: number;
    allocatedUnitsOriginal1?: number;
    totalUnits1?: number;
    updatedBy1?: string;
    updatedOn1?: Date;
    availableUnits2?: number;
    allocatedUnits2?: number;
    allocatedUnitsOriginal2?: number;
    totalUnits2?: number;
    updatedBy2?: string;
    updatedOn2?: Date;
    availableUnits3?: number;
    allocatedUnits3?: number;
    allocatedUnitsOriginal3?: number;
    totalUnits3?: number;
    updatedBy3?: string;
    updatedOn3?: Date;
    availableUnits4?: number;
    allocatedUnits4?: number;
    allocatedUnitsOriginal4?: number;
    totalUnits4?: number;
    updatedBy4?: string;
    updatedOn4?: Date;
    name?: string;
    type?: 'service' | 'ip';
    id?: number;
    parentId?: number;
    parentName?: string;
    editable?: boolean;
    mappingID?: number;
    contents?: IAuthAllocResult[];
    serviceCodeAvail1?: number;
    serviceCodeAvail2?: number;
    serviceCodeAvail3?: number;
    serviceCodeAvail4?: number;
    caregiverAvail1?: number;
    caregiverAvail2?: number;
    caregiverAvail3?: number;
    caregiverAvail4?: number;
    relationshipStart?: Date;
    relationshipEnd?: Date;
}

@Injectable({
  providedIn: 'root',
})

export class AuthAllocComponentService implements OnDestroy {
  private readonly _results: BehaviorSubject<IAuthAllocResult[]>
  = new BehaviorSubject<IAuthAllocResult[]>(
    []
  );
  private readonly _parameters: BehaviorSubject<IAuthAllocParameters>
  = new BehaviorSubject<IAuthAllocParameters>(
    {}
  );

  private readonly _destroying$ = new Subject<void>();

  constructor(
    public toastr: ToastrService,
    public apiService: PeopleFunctionService,
  ) {}


  public getSearchParameters(): Observable<any> {
    return this._parameters;
  }

  public setSearchParameters(data: any) {
    this._parameters?.next(data);
  }

  public getSearchResults(): Observable<any> {
    return this._results;
  }

  public setSearchResults(data: any) {
    this._results?.next(data);
  }

  public clear(): void {
    this.clearEntity();
    this.clearParameters();
  }

  public clearEntity(): void {
    this._results?.next(null);
  }

  public clearParameters(): void {
    this._parameters?.next(null);
  }

  ngOnDestroy(): void {
    this._destroying$.next(null);
    this._destroying$.complete();
  }

}
