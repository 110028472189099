import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { CommonConstants } from '../../shared/constants/common-constants';
import { UnloadPersonsTypeSearchCriteria } from '../../u2-person/person-state/person.actions';

@Component({
  selector: 'CDMS-Portal-U2-nav-item',
  templateUrl: './nav-item.component.html',
  styles: [
  ]
})
export class NavItemComponent implements OnInit {

  @ViewChild('menu', { static: true }) menu;
  @Input() items: { name: string; children: string[] }[];
  @Input() state: boolean;
  isMenuSm: boolean;
  ismenuloaded = false;
  menuTimeInterval: any;

  constructor(private readonly store: Store<any>) { }

  ngOnInit(): void {
    this.openselectedMenu();
  }

  menuClicked(event: any, screenName: string) {
    const mainNavBar = document.querySelector('.sidenavbar.sidenav-sm');
    this.isMenuSm = mainNavBar ? true : false;
    const screenHeight = window.innerHeight;
    const el = event.target;
    const elTop = event.clientY;
    const elofsety = event.offsetY;
    const elnewtop = elTop - elofsety;
    const submenuHeight = (screenHeight - elnewtop) - 15;
    const parentEl = el.parentElement;
    const childNode = parentEl.querySelector('.sub-menu');
    const elArrow = el.querySelector('b');
    if (screenName !== CommonConstants.CustomerService) {
      this.store.dispatch(new UnloadPersonsTypeSearchCriteria());
    }
    // Side Nav Collapsed condition
    if (this.isMenuSm) {
      if (elArrow.classList.contains('right')) {
        elArrow.classList.remove('right');
        elArrow.classList.add('down');
      } else if (elArrow.classList.contains('down')) {
        elArrow.classList.remove('down');
        elArrow.classList.add('right');
      }
      // Open Sub Menu in Side Nav Collapsed state
      if (!parentEl.parentElement.parentElement.classList.contains('show') || el.classList.contains('nochild')) {
        const openSubmenu = document.querySelector('.show.submenu');
        if (openSubmenu) {
          openSubmenu.classList.remove('show', 'submenu');
        }
      }
      const smparentEl = el.parentElement;
      const smchildNode = smparentEl.querySelector('.sub-menu');
      // Toggle the Sub menu in Side Nav Collapsed state
      if (smchildNode) {
        if (smchildNode.classList.contains('show')) {
          smchildNode.classList.remove('show', 'submenu');
          smchildNode.style.top = 0;
          smchildNode.style.maxHeight = 0;
        } else {
          smchildNode.classList.add('show', 'submenu');
          smchildNode.style.top = elnewtop + 'px';
          smchildNode.style.maxHeight = submenuHeight + 'px';
        }
      }
    } else {
      // Changing the Arrow when menu is expanded or Collapsed
      if (elArrow.classList.contains('right')) {
        elArrow.classList.remove('right');
        elArrow.classList.add('down');
      } else if (elArrow.classList.contains('down')) {
        elArrow.classList.remove('down');
        elArrow.classList.add('right');
      }
      if (childNode) {
        // Toggle the Sub menu
        if (childNode.offsetHeight > 0) {
          childNode.classList.remove('show');
        } else {
          childNode.classList.add('show');
        }
      }
    }
  }

  openselectedMenu() {
    // Time interval till the menu is loaded
    if (!this.ismenuloaded) {
      this.menuTimeInterval = setInterval(() => {
        const mainNavBar = document.querySelector('#mainsidenav');
        if (mainNavBar) {
        const selectedMenu = mainNavBar.querySelector('.selected');
        // Expand the menu selected
        if (selectedMenu) {
          this.ismenuloaded = true;
          clearInterval(this.menuTimeInterval); // Clear time interval when menu is loaded
          const parentEl1 = selectedMenu.parentElement.parentElement.parentElement.parentElement.parentElement;
          const parentEl2 = parentEl1.parentElement.parentElement.parentElement.parentElement;
          const parentEl3 = parentEl2.parentElement.parentElement.parentElement.parentElement;
          // Menu Level if more than one child item
          if (parentEl1.classList.contains('haschild')) {
            this.openMenuArrow(parentEl1);
            parentEl1.classList.add('childselected');
            const parentEl1submenu = parentEl1.querySelector('.sub-menu');
            if (parentEl1submenu) {
              parentEl1submenu.classList.add('show');
            }
          }
          // Menu Level if one child item
          if (parentEl2.classList.contains('haschild')) {
            this.openMenuArrow(parentEl2);
            parentEl2.classList.add('childselected');
            const parentEl2submenu = parentEl2.querySelector('.sub-menu');
            if (parentEl2submenu) {
              parentEl2submenu.classList.add('show');
            }
          }
          // First Level Menu check
          if (parentEl3.classList.contains('haschild')) {
            this.openMenuArrow(parentEl3);
            parentEl3.classList.add('childselected');
            const parentEl3submenu = parentEl3.querySelector('.sub-menu');
            if (parentEl3submenu) {
              parentEl3submenu.classList.add('show');
            }
          }
        }
      }
      }, 100);
    }
  }

  openMenuArrow(element: any) {
    const arrow = element.querySelector('b');
    if (arrow) {
      arrow.classList.remove('right');
      arrow.classList.add('down');
    }
  }
  trimScreenName(screenName: string, allowedChars: number = 25) {
    let choice = allowedChars;
    if (this.state === true) {
      choice = 6;
    }
    const ellipsesCount = 3;
    if (screenName && choice > 0 && choice - ellipsesCount > 0) {
      screenName = (screenName.length > choice) ?
        screenName.substring(0, choice - ellipsesCount) + '...' : screenName;
    }
    return screenName;
  }
}


