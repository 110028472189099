import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Subject, take, takeUntil } from 'rxjs';
import { AppInsightsService } from 'src/app/core/services/app-insights.service';
import { PersonCommonService } from 'src/app/shared/services/person-common.service';
import { environment } from 'src/environments/environment';
import { AppLoaderService } from '../controllers/app/loaders.controller';
import { ICompany } from '../core/models/company';
import { PeopleFunctionService } from '../core/services/peopleapi/peoplefunctionapi.services';
import { CommonFunctions } from '../shared/utilities/common-functions';
import { ScreenUser, Screens, UserRoleCompany } from './../core/services/u2api.services';
import { IamWrapperService } from '../core/services/iam-wrapper.service';
import { FeatureStateService } from '../shared/services/featureStateService';
import { ConfigurationFunctionService } from '../core/services/configurationapi/configurationfunctionapi.services';
import { CommonConstants } from '../shared/constants/common-constants';

@Component({
  selector: 'CDMS-Portal-U2-main-sidenav',
  templateUrl: './main-sidenav.component.html',
  styleUrls: ['./main-sidenav.component.scss']
})
export class MainSidenavComponent implements OnInit, OnChanges, OnDestroy {

  public RolesArray: ScreenUser;
  public parentScreens: any = [];
  public screenList: Screens[] = [];
  public accessRightsList = '';
  public firstMenuItem: string;
  public objectId: any;
  public personIdList: any = [];
  public canImpersonate = false;
  public navigationStartUrl = '/';
  public shift = false;
  public arrowDown = false;
  public arrowUp = false;
  public ctrl = false;
  public environment = '';
  private readonly _destroying$ = new Subject<void>();
  @Input() sidenavToggleClass: boolean;
  @Input() state: boolean;
  @Input() currnetCompany: any;
  @Output() userHasNoAccessEmitter = new EventEmitter();
  @Output() firstMenuUrlEmitter = new EventEmitter();
  @Output() addedLastCompany = new EventEmitter();

  constructor(
    private readonly configurationFunctionService: ConfigurationFunctionService,
    private readonly iamWrapperService: IamWrapperService,
    private readonly router: Router,
    private readonly peopleService: PeopleFunctionService,
    private readonly aiService: AppInsightsService,
    public personCommonService: PersonCommonService,
    private readonly appLoaderService: AppLoaderService,
    public toastr: ToastrService,
    private readonly commonFunctions: CommonFunctions,
    private readonly featureService: FeatureStateService
    ) {
    this.environment = environment.envName;
  }

  ngOnInit() {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        this.navigationStartUrl = event.url;
      }
    });
    this.iamWrapperService.PopulateScreenPermissions.subscribe((data) => {
      sessionStorage.setItem('screenpermssions', btoa(JSON.stringify(data)));
      this.screenList = data;
      this.parentScreens = data.filter(x => x.parentId === null && x.display);
      this.GenerteList();
      this.userHasNoAccessEmitter.emit(false);
      sessionStorage.setItem('userAccess', btoa(this.accessRightsList));
      this.iamWrapperService.CompanyChangeInProgress = false;
    });
  }

  navscroll() {
    if (this.sidenavToggleClass) {
      this.removeAllSubMenu();
    }
  }

  removeAllSubMenu() {
    const openSubmenu = Array.from(document.querySelectorAll('.show.submenu'));
    openSubmenu.forEach(element => {
      element.classList.remove('show', 'submenu');
    });
    const arrows = Array.from(document.getElementsByClassName('arrow down'));
    arrows.forEach(element => {
      element.classList.remove('down');
      element.classList.add('right');
    });
  }

  showAllSubMenu() {
    const closedSubmenu = Array.from(document.getElementsByClassName('sub-menu'));
    closedSubmenu.forEach(element => {
      element.classList.add('show', 'submenu');
    });
    const arrows = Array.from(document.getElementsByClassName('arrow right'));
    arrows.forEach(element => {
      element.classList.remove('right');
      element.classList.add('down');
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.currnetCompany && changes.currnetCompany.currentValue) {
      this.parentScreens = [];
      this.screenList = [];
      this.accessRightsList = '';
      this.loadSideNav();
    }
  }

  loadSideNav() {
    this.iamWrapperService.getUserPermissions()
    .pipe(
      take(1),
      takeUntil(this._destroying$)
    ).subscribe({
      next: (data: ScreenUser) => {
        this.RolesArray = data;
        if (this.RolesArray && this.RolesArray.roles && this.RolesArray.roles.length > 0) {
          const roleIds: Array<number> = [];
          this.RolesArray.roles.forEach(element => {
            roleIds.push(element.roleId);
            element.screens.forEach(screen => {
              if (this.screenList) {
                const screenIdx = this.screenList.findIndex(x => x.screenId === screen.screenId);
                if (screenIdx > -1) {
                  const existingScreen = this.screenList.splice(screenIdx, 1);
                  if (existingScreen) {
                    screen.permissions.push(...existingScreen[0].permissions);
                    screen.permissions = screen.permissions.filter(function(elem, index, self) {
                      return index === self.findIndex(x => x.permissionId === elem.permissionId);
                    });
                  }
                }
                this.screenList.push(screen);
              } else {
                this.screenList.push(screen);
              }
            });
          });
          const userRoleCompany = new UserRoleCompany();
          userRoleCompany.companyId = <number>(<ICompany>this.currnetCompany.companyCode).CompanyId;
          userRoleCompany.roleId = roleIds;
          this.iamWrapperService.addLatestAccessedCompany(userRoleCompany).subscribe(dt => {
            this.addedLastCompany.emit(dt.map(i => ({ CompanyId : i.companyId, CompanyName: i.companyName })));
          });

          if (this.RolesArray.roles.some(x => x.canImpersonate)) {
            this.canImpersonate = true;
          }

          sessionStorage.setItem('CanImpersonate', btoa(JSON.stringify(this.canImpersonate)));
          sessionStorage.setItem('screenpermssions', btoa(JSON.stringify(this.screenList)));
          if (!this.featureService.useIamApis()) {
            this.parentScreens = this.screenList.filter(x => x.parentId === null && x.display);
            this.GenerteList();
            this.userHasNoAccessEmitter.emit(false);
            sessionStorage.setItem('userAccess', btoa(this.accessRightsList));
          }
          if (this.featureService.useIamApis() && this.iamWrapperService.CompanyChangeInProgress){
            this.commonFunctions.showLoading();
            this.configurationFunctionService.getCompanyInfo()
              .pipe(
                take(1),
                takeUntil(this._destroying$)
              ).subscribe({
                next: (data) => {
                  this.commonFunctions.hideLoading();
                  sessionStorage.setItem(CommonConstants.CompanyFeaturesStorageKey, btoa(JSON.stringify(data.features)));
                  sessionStorage.setItem(CommonConstants.CompanyConfigStorageKey, btoa(JSON.stringify(data.configuration)));
                  if (this.featureService.useIamApis()) {
                    this.iamWrapperService.handleDisplayBasedOnCompanies();
                  }
                },
                error: error => {
                  this.commonFunctions.hideLoading();
                  this.commonFunctions.handleErrorResponse(error, this.toastr, CommonConstants.UnableToLoadFeatures);
                }
              });
          }
          this.SetUserDataInAIContext(data);
          if (this.currnetCompany && this.currnetCompany.fromCompanyChange) {
            this.router.navigateByUrl(this.firstMenuItem);
          }
        } else {
          this.userHasNoAccessEmitter.emit(true);
        }
        if (data.objectId !== undefined) {
          this.objectId = data.objectId;
          this.getPersonByObjectId(this.objectId);
          sessionStorage.setItem('objectId', this.objectId);
        }
      },
      error: error => {
        this.commonFunctions.handleErrorResponse(error, this.toastr);
        this.userHasNoAccessEmitter.emit(true);
      }
    });
  }

  public getPersonByObjectId(inputObjectId) {
    this.appLoaderService.setLoader('getPersonIdByUserObjectId', true);
    this.peopleService.getPersonIdByUserObjectId(inputObjectId)
    .pipe(
      take(1),
      takeUntil(this._destroying$)
    ).subscribe({
      next: (result) => {
        this.appLoaderService.setLoader('getPersonIdByUserObjectId', false);
        if (result) {
          this.personIdList = result;
          sessionStorage.setItem('PersonListFromObjectId', JSON.stringify(this.personIdList));
          this.personCommonService.getUnreadCount(true);
        }
      },
      error: error => {
        this.appLoaderService.setLoader('getPersonIdByUserObjectId', false);
        this.commonFunctions.handleErrorResponse(error, this.toastr);
      }
    });
  }


  private SetUserDataInAIContext(data: ScreenUser) {
    const roleNames = [];
    data.roles.forEach(x => roleNames.push(x.roleName));
    const userData = { ...data, roleNames: roleNames.join(', ') };
    userData.roles = undefined;
    this.aiService.userInfo.UserRole = userData.roleNames;
  }

  getChilds(_parentId: number) {
    return this.screenList.filter(x => x.parentId === _parentId && x.isAuthorized);
  }

  GenerteList() {
    const currentUrl = this.router.url;
    this.parentScreens.forEach(element => {
      const elchilderns = this.getChilds(element.screenId);
      const parentHasCurrentRoute = elchilderns.filter(x => x.path && x.path !== '' && x.display === true
        && currentUrl.indexOf(x.path) > -1);
      if (parentHasCurrentRoute && parentHasCurrentRoute.length > 0) {
        element['expanded'] = true;
      } else {
        element['expanded'] = false;
      }
      element['childerns'] = elchilderns;
      if (elchilderns && elchilderns.length) {
        this.accessRightsList += elchilderns.map(x => x.path).join(',');
      }

      this.ProcessChildMenu(element, currentUrl);
      this.accessRightsList += ',';
    });
    this.accessRightsList = this.accessRightsList.split(',').filter(x => x).join(',');
    const newMenuList = this.parentScreens;
    const sortedParent = newMenuList.sort((a, b) => a.displayOrder < b.displayOrder ? -1 : 1);
    sortedParent.forEach(element => {
      element.childerns = element.childerns.sort((a, b) => a.displayOrder < b.displayOrder ? -1 : 1);
    });
    const firstParentMenu = this.parentScreens[0].childerns.find(x => x.display === true);
    this.firstMenuItem = (firstParentMenu === undefined || firstParentMenu === null) ? '' : firstParentMenu.path;
    this.firstMenuUrlEmitter.emit(this.firstMenuItem);
    if (this.router.url === '/' && this.navigationStartUrl === '/') {
      this.router.navigateByUrl(this.firstMenuItem);
    } else {
      this.router.navigateByUrl(this.navigationStartUrl);
    }
  }

  private ProcessChildMenu(element: any, currentUrl: string) {
    if (element && element.childerns && element.childerns.length > 0) {
      this.accessRightsList += ',';
      element.childerns.forEach(childrens => {
        this.accessRightsList += ',';
        const parentchildrens = this.getChilds(childrens.screenId);
        childrens['childerns'] = parentchildrens;
        const childHasCurrentRoute = parentchildrens.filter(x => x.path && x.path !== '' && x.display === true
          && currentUrl.indexOf(x.path) > -1);
        if (childHasCurrentRoute && childHasCurrentRoute.length > 0) {
          element['expanded'] = true;
          childrens['expanded'] = true;
        } else {
          if (element['expanded']) {
            element['expanded'] = false;
          }
          childrens['expanded'] = false;
        }
        this.accessRightsList += childrens['childerns'].map(x => x.path).join(',');
        this.ProcessChildMenu(childrens, currentUrl);
      });

    }
  }

  ngOnDestroy(): void {
    this._destroying$.next(null);
    this._destroying$.complete();
  }
}
