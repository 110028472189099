import { map, Observable } from 'rxjs';
import { UserModel } from '../services/u2api.services';
import { UserWithRolesDtoGridResponseBase } from '../services/usersapi/usersapi.services';
import { IamWrapperConstants } from '../iamWrapperConstants';

export class GetAllUsersTransform {
  static transformOutput(observableResponse: Observable<UserWithRolesDtoGridResponseBase>): Observable<UserModel[]> {
    return observableResponse.pipe(
      map(usersDtos => {
        const userModels: UserModel[] = [];
        usersDtos.items.forEach(usersDto => {
          const userModel = new UserModel({
            userRoleId: 0,
            userId: usersDto.userId,
            email: usersDto.email,
            userName: [usersDto.firstName, usersDto.middleName, usersDto.lastName].filter(name => name).join(' '),
          });
          userModel[IamWrapperConstants.UserObjectIdKey] = usersDto.objectId;
          userModels.push(userModel);
        });
        return userModels;
      }),
    );
  }
}
