import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';


@Injectable({
  providedIn: 'root',
})

export class AppLoaderService implements OnDestroy {
  private readonly _loader: BehaviorSubject<any> = new BehaviorSubject<any>({
    getPersonIdByUserObjectId: false,
    goToFunction: false,
    cgvController: false,
  }
  );

  private readonly _destroying$ = new Subject<void>();

  constructor(
  ) {}

  public setLoader(loader: string, data: boolean) {
    const loaderNow = this._loader.getValue();
    loaderNow[loader] = data;
    this._loader.next(loaderNow);
  }

  public clearLoader(loader: string): void {
    const loaderNow = this._loader.getValue();
    loaderNow[loader] = false;
    this._loader.next(loaderNow);
  }

  public getLoader(): Observable<boolean> {
    return this._loader;
  }

  ngOnDestroy(): void {
    this._destroying$.next(null);
    this._destroying$.complete();
  }

}
