import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BreadcrumbCommonService {

  constructor() { }
  // Observable string sources
  private readonly emitChangeSource = new Subject<any>();
  // Observable string streams
  getChange = this.emitChangeSource.asObservable();
  // Service message commands
  setChange(change: any) { this.emitChangeSource.next(change); }
}
